export const galleryDefaultState = { gallery: null };

export default function GalleryReducer(
  state = galleryDefaultState,
  action = {}
) {
  switch (action.type) {
    case 'GALLERY_ADD':
      return { ...state, gallery: action.payload };
    case 'GALLERY_REMOVE':
      return { ...state, gallery: null };
    default:
      return state;
  }
}
