import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import PainelExpositor from './PainelExpositor';
import Midias from '../Midias';
import I18n from '../../I18n';
import Menu from './Menu';
import { Container, Logo, Nav } from './styles';

const CabecalhoDesktop = ({ logo, medias, menu, mostrarSubMenu }) => {
  const { lang } = useParams();

  return (
    <Container fluid>
      <Nav>
        <PainelExpositor />

        <Midias midias={medias} />

        <Link to={`/${lang}`}>
          <Logo src={logo} alt="" />
        </Link>

        <I18n />
      </Nav>

      <Menu menu={menu} mostrarSubMenu={mostrarSubMenu} />
    </Container>
  );
};

CabecalhoDesktop.propTypes = {
  medias: PropTypes.array,
  menu: PropTypes.array,
  mostrarSubMenu: PropTypes.any,
  logo: PropTypes.any,
};

export default CabecalhoDesktop;
