import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import traducoes from '../locales';

export const I18nProvider = ({ children }) => {
  const [done, setDone] = useState(false);

  async function init() {
    i18n.use(LanguageDetector).use(initReactI18next).init({
      resources: traducoes,
      fallbackLng: 'pt-BR',
      defaultNS: 'traducoes',
    });
    setDone(true);
  }

  useEffect(() => {
    init();
    return function cleanup() {};
  }, []);

  function render() {
    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
  }

  return done ? render() : null;
};

I18nProvider.propTypes = {
  children: propTypes.any,
};

export default I18nProvider;
