import { useEffect, useState } from 'react';
import api from '../../services/api';

export default function RepositoryPost(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setPosts([]);
  }, [query]);
  useEffect(async () => {
    setLoading(true);
    setError(false);
    await api.get(`/blnews?tipo=2&page=${pageNumber}`).then((response) => {
      setPosts((prevPosts) => {
        return [
          ...new Set([
            ...prevPosts,
            ...response.data._embedded.post.map((p) => p),
          ]),
        ];
      });
      setHasMore(response.data.page_count > pageNumber);
      setLoading(false);
    });
  }, [query, pageNumber]);

  return { loading, error, posts, hasMore };
}
