import React, { useCallback, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';

import LANGUAGES from '../../i18n/languages';

const MultiLanguageRoute = (props) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  function langIsValid(lang) {
    return (
      Object.values(LANGUAGES).filter(
        (langValue) =>
          langValue && langValue.urlLang && langValue.urlLang === lang
      ).length > 0
    );
  }

  const inicializar = useCallback(
    ({ computedMatch, path, i18nCurrentLang, changeLanguage }) => {
      setLoading(true);

      const lang = computedMatch.params.lang;
      const hasLangValid = langIsValid(lang);
      const hasPath = !!path;
      const isBasePath = path === '/';

      let urlToRedirect = '';

      if (!hasPath || isBasePath) {
        urlToRedirect = `/${LANGUAGES.default}`;
      } else if (lang) {
        if (!hasLangValid) {
          urlToRedirect = `/${LANGUAGES.default}${window.location.pathname}`;
        } else if (LANGUAGES[lang].code !== i18nCurrentLang) {
          changeLanguage(LANGUAGES[lang].code);
        }
      }

      if (urlToRedirect !== '') {
        history.push(urlToRedirect);
      }

      setLoading(false);
    },
    [setLoading]
  );

  useEffect(() => {
    inicializar(props);
    return function cleanup() {};
  }, [props, inicializar]);

  if (!loading) {
    return <Route {...props} />;
  } else {
    return null;
  }
};

export default MultiLanguageRoute;
