import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaBars } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import I18n from '../../I18n';
import Midias from '../Midias';

import {
  Collapse,
  Logo,
  Nav,
  NavExternalLink,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from './styles';

const CabecalhoMobile = ({ logo, menu, medias }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { lang } = useParams();

  const toggle = () => setIsOpen(!isOpen);

  const renderMenuItem = ({ link, text, externalLink }, idx) => (
    <NavItem key={`cabecalho-mobile-${idx}`}>
      {externalLink ? (
        <NavExternalLink href={link} target="_blank">
          {text}
        </NavExternalLink>
      ) : (
        <NavLink to={link}>{text}</NavLink>
      )}
    </NavItem>
  );

  return (
    <div>
      <Navbar expand="md" fixed="top">
        <NavbarBrand to={`/${lang}`}>
          <Logo src={logo} alt="" />
        </NavbarBrand>
        <div>
          <I18n />
          <NavbarToggler onClick={toggle}>
            <FaBars />
          </NavbarToggler>
        </div>
        <Collapse isOpen={isOpen} onClick={toggle} navbar>
          <Nav className="mr-auto" navbar>
            {menu.map(renderMenuItem)}
            <NavExternalLink
              href="https://paineldoexpositor.com.br"
              target="_blank"
            >
              Painel do Expositor
            </NavExternalLink>
          </Nav>

          <div className="text-center">
            <Midias className="d-inline-block" midias={medias} />
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};

CabecalhoMobile.propTypes = {
  medias: PropTypes.array,
  menu: PropTypes.array,
  logo: PropTypes.any,
};

export default CabecalhoMobile;
