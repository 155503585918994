export default {
  pt: {
    code: 'pt-BR',
    urlLang: 'pt',
  },
  en: {
    code: 'en',
    urlLang: 'en',
  },
  es: {
    code: 'es',
    urlLang: 'es',
  },
  default: 'pt',
};
