import React, { useEffect } from 'react';
import useScroll from '../../hooks/useScroll';

// eslint-disable-next-line react/prop-types
const TemplateRoute = ({ Comp, Template, ...props }) => {
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Template>
      <Comp {...props} />
    </Template>
  );
};

export default TemplateRoute;
