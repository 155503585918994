/* eslint-disable no-unused-vars */
import React, { useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import Head from '../../components/Head';
import blNewsService from '../../services/blNewsService';

import placeholderNewsHome260x128 from '../../assets/placeholder-news-home-260x128.svg';
import {
  Secao,
  Col,
  Noticia,
  ImageCover,
  NoticiaImagemContainer,
  NoticiaTitulo,
  Row,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoMenu,
  SecaoMenuItem,
  SecaoMenuLista,
  SecaoMenuNav,
  SecaoTitulo,
  Tradutor,
  Link,
  ReloadPosts,
  TextReload,
  SectionCenter,
} from './styles';
import RepositoryPost from './RepositoryPost';

function Noticias() {
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const { lang } = useParams();
  const { t } = useTranslation();
  const { switchSizeImage } = blNewsService();

  const { posts, hasMore, loading, error } = RepositoryPost(query, pageNumber);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <Secao className="mb-4">
      <Head
        title={t('paginas.noticias.secaoNoticias.titulo')}
        description={t('paginas.noticias.secaoNoticias.titulo')}
      />

      <SecaoCabecalho className="mb-5">
        <SecaoTitulo>
          <Tradutor path="paginas.noticias.secaoNoticias.titulo" />
        </SecaoTitulo>
        <SecaoMenu>
          <SecaoMenuNav>
            <SecaoMenuLista>
              <SecaoMenuItem>home</SecaoMenuItem>
              <SecaoMenuItem>
                <SecaoBotaoVoltar />
              </SecaoMenuItem>
            </SecaoMenuLista>
          </SecaoMenuNav>
        </SecaoMenu>
      </SecaoCabecalho>
      <Row>
        {posts.map((data, index) => {
          if (posts.length === index + 1) {
            return (
              <div ref={lastBookElementRef} key={data.id}>
                <Col md="6" ref={lastBookElementRef} key={data.id}>
                  <Link
                    to={`/${lang}/noticias/${data.slug}`}
                    title={data.description}
                  >
                    <Noticia>
                      <NoticiaImagemContainer>
                        <ImageCover
                          src={
                            data.image.length
                              ? switchSizeImage(data.image, 'md')
                              : placeholderNewsHome260x128
                          }
                          alt={data.image_description}
                        />
                      </NoticiaImagemContainer>
                      <NoticiaTitulo>{data.title}</NoticiaTitulo>
                    </Noticia>
                  </Link>
                </Col>
              </div>
            );
          } else {
            return (
              <Col md="6" key={index}>
                <Link
                  to={`/${lang}/noticias/${data.slug}`}
                  title={data.description}
                >
                  <Noticia>
                    <NoticiaImagemContainer>
                      <ImageCover
                        src={
                          data.image.length
                            ? switchSizeImage(data.image, 'md')
                            : placeholderNewsHome260x128
                        }
                        alt={data.image_description}
                      />
                    </NoticiaImagemContainer>
                    <NoticiaTitulo>{data.title}</NoticiaTitulo>
                  </Noticia>
                </Link>
              </Col>
            );
          }
        })}
        {loading && (
          <SectionCenter>
            <Loader />
          </SectionCenter>
        )}
        <div>{error && 'Error'}</div>
      </Row>
    </Secao>
  );
}

export default Noticias;
