/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Embed,
  Iframe,
  ImagemComOverlayMerkator,
  IconOverlay,
  ImagemPlaceholder,
  Modal,
  ModalBody,
  Overlay,
  VideoStyled,
} from './styles';

const Video = ({
  videoEmbedded,
  imageDescription,
  imagemPlaceholder,
  showIcon,
  ...props
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <VideoStyled {...props}>
      <ImagemComOverlayMerkator>
        <ImagemPlaceholder
          src={imagemPlaceholder}
          alt={imageDescription}
          className="img-fluid"
        />
        {videoEmbedded.length > 0 && (
          <Overlay onClick={toggle}>{showIcon && <IconOverlay />}</Overlay>
        )}
      </ImagemComOverlayMerkator>
      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalBody>
          <Embed>
            <Iframe
              src={`https://www.youtube.com/embed/${videoEmbedded}`}
              allowFullScreen
            />
          </Embed>
        </ModalBody>
      </Modal>
    </VideoStyled>
  );
};

Video.propTypes = {
  imagemPlaceholder: PropTypes.any,
  imageDescription: PropTypes.any,
};

export default Video;
