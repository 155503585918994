import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useWindowSize from '@alweb-merkator/shared/hooks/useWindowSize';
import EMerkatorTalkPt from '../../../assets/e-merkator-talk-pt.png';
import EMerkatorTalkEs from '../../../assets/e-merkator-talk-es.png';
import EMerkatorTalkEn from '../../../assets/e-merkator-talk-en.png';
import EMerkatorTalkPtMobile from '../../../assets/e-merkator-talk-pt-mobile.png';
import EMerkatorTalkEsMobile from '../../../assets/e-merkator-talk-es-mobile.png';
import EMerkatorTalkEnMobile from '../../../assets/e-merkator-talk-en-mobile.png';
import EMerkatorBlogPt from '../../../assets/e-merkator-blog-pt.png';
import EMerkatorBlogEs from '../../../assets/e-merkator-blog-es.png';
import EMerkatorBlogEn from '../../../assets/e-merkator-blog-en.png';
import EMerkatorCastPt from '../../../assets/e-merkator-cast-pt.png';
import EMerkatorCastEs from '../../../assets/e-merkator-cast-es.png';
import EMerkatorCastEn from '../../../assets/e-merkator-cast-en.png';
import EMerkatorPushPt from '../../../assets/e-merkator-push-pt.png';
import EMerkatorPushEs from '../../../assets/e-merkator-push-es.png';
import EMerkatorPushEn from '../../../assets/e-merkator-push-en.png';
import EMerkatorTvPt from '../../../assets/e-merkator-tv-pt.png';
import EMerkatorTvEs from '../../../assets/e-merkator-tv-es.png';
import EMerkatorTvEn from '../../../assets/e-merkator-tv-en.png';
import {
  Row,
  Col,
  Botao,
  TextoBotao,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoSubTitulo,
  Tradutor,
  ImagemCover,
  ImagemCoverExterno,
  BigImagemCover,
} from './styles';

// eslint-disable-next-line react/prop-types
function SecaoEMerkator({ contemProgramacao = false }) {
  const { lang } = useParams();
  const { t } = useTranslation();
  const secaoId = t('slugs.eMerkator');
  const windowSize = window.screen.width;

  const [eMerkatorTalks, setEmerkatorTalks] = useState('');
  const [eMerkatorBlog, setEmerkatorBlog] = useState('');
  const [eMerkatorCast, setEmerkatorCast] = useState('');
  const [eMerkatorPush, setEmerkatorPush] = useState('');
  const [eMerkatorTv, setEmerkatorTv] = useState('');

  function setImageLanguage() {
    switch (lang) {
      case 'es':
        setEmerkatorTalks(
          windowSize >= 768 ? EMerkatorTalkEs : EMerkatorTalkEsMobile
        );
        setEmerkatorBlog(EMerkatorBlogEs);
        setEmerkatorCast(EMerkatorCastEs);
        setEmerkatorPush(EMerkatorPushEs);
        setEmerkatorTv(EMerkatorTvEs);
        break;
      case 'en':
        setEmerkatorTalks(
          windowSize >= 768 ? EMerkatorTalkEn : EMerkatorTalkEnMobile
        );
        setEmerkatorBlog(EMerkatorBlogEn);
        setEmerkatorCast(EMerkatorCastEn);
        setEmerkatorPush(EMerkatorPushEn);
        setEmerkatorTv(EMerkatorTvEn);
        break;
      default:
        setEmerkatorTalks(
          windowSize >= 768 ? EMerkatorTalkPt : EMerkatorTalkPtMobile
        );
        setEmerkatorBlog(EMerkatorBlogPt);
        setEmerkatorCast(EMerkatorCastPt);
        setEmerkatorPush(EMerkatorPushPt);
        setEmerkatorTv(EMerkatorTvPt);
    }
  }
  useEffect(() => {
    setImageLanguage();
  }, []);
  return (
    <Secao id={secaoId} className="mb-4 mb-30">
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path="paginas.inicial.secaoEMerkator.titulo" />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      <SecaoSubTitulo className="mb-4">
        <Tradutor path="paginas.inicial.secaoEMerkator.subTitulo" />
      </SecaoSubTitulo>

      <Row className="mb-30">
        {contemProgramacao ? (
          <>
            <Col md="8">
              <BigImagemCover
                to={`/${lang}/${t('slugs.programacoes')}`}
                style={{ backgroundImage: `url(${eMerkatorTalks})` }}
              />
            </Col>
            <Col md="4">
              <Botao to={`/${lang}/${t('slugs.programacoes')}`}>
                <TextoBotao>
                  {t('componentes.secaoEMerkator.programacaoCompleta')}
                </TextoBotao>
              </Botao>
            </Col>
          </>
        ) : (
          <Col>
            <img
              className="w-100"
              src={eMerkatorTalks}
              alt="e-merkator talks"
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col md="3">
          <ImagemCoverExterno
            href="https://open.spotify.com/show/6TTP8PAUrFv8DNtMW8zVsL"
            target="_blank"
            style={{ backgroundImage: `url(${eMerkatorCast})` }}
          />
        </Col>
        <Col md="3">
          <ImagemCover
            to={`/${lang}/${t('slugs.eMerkatorTV')}`}
            style={{ backgroundImage: `url(${eMerkatorTv})` }}
          />
        </Col>
        <Col md="3">
          <ImagemCoverExterno
            href="https://blog.merkatorfeiras.com.br/"
            target="_blank"
            style={{ backgroundImage: `url(${eMerkatorBlog})` }}
          />
        </Col>
        <Col md="3">
          <ImagemCoverExterno
            href="http://merkatorfeira.rds.land/captacao_leads"
            target="_blank"
            style={{ backgroundImage: `url(${eMerkatorPush})` }}
          />
        </Col>
      </Row>
    </Secao>
  );
}

export default SecaoEMerkator;
