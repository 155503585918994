/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Imagem, ModalBody, VideoStyled, ButtonClose } from './styles';
import { ImCross } from 'react-icons/im';

const AlertModal = ({
  imageDescription,
  imageModal,
  showModal,
  urlImage,
  urlTitle,
  target,
  ...props
}) => {
  const [modal, setModal] = useState(showModal);
  const toggle = () => setModal(!modal);
  return (
    <VideoStyled {...props}>
      <Modal size="md" isOpen={modal} centered toggle={toggle}>
        <ModalBody>
          <ButtonClose onClick={toggle}>
            <ImCross />
          </ButtonClose>
          <a href={urlImage} title={urlTitle} rel="noreferrer" target={target}>
            <Imagem className="" src={imageModal} />
          </a>
        </ModalBody>
      </Modal>
    </VideoStyled>
  );
};

AlertModal.propTypes = {
  imageModal: PropTypes.any,
};

export default AlertModal;
