import styled from 'styled-components';
import {
  FacebookShareButton as RshFacebookShareButton,
  TwitterShareButton as RshTwitterShareButton,
  WhatsappShareButton as RshWhatsappShareButton,
} from 'react-share';
import { FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa';

import BannerInternas from '../../components/BannerInternas';
import {
  SecaoMenuItem as CompSecaoMenuItem,
  SecaoBotaoVoltar as CompSecaoBotaoVoltar,
} from '../../components/Secao';

export { Link } from 'react-router-dom';

export { Col, Row } from 'reactstrap';

export { default as ImageCover } from '../../components/ImageCover';

export { Tradutor } from '../../components/I18n';

export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../components/Secao';

export const ShareContainer = styled.div`
  text-align: right;
  margin-bottom: 30px;
`;

export const FacebookShareButton = styled(RshFacebookShareButton)`
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-width: 1px !important;
  border-radius: 50% !important;
  border-style: solid !important;
  width: 50px !important;
  height: 50px !important;
  margin-left: 5px;
`;

export const TwitterShareButton = styled(RshTwitterShareButton)`
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-width: 1px !important;
  border-radius: 50% !important;
  border-style: solid !important;
  width: 50px !important;
  height: 50px !important;
  margin-left: 5px;
`;

export const WhatsappShareButton = styled(RshWhatsappShareButton)`
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-width: 1px !important;
  border-radius: 50% !important;
  border-style: solid !important;
  width: 50px !important;
  height: 50px !important;
  margin-left: 5px;
`;

export const FacebookIcon = styled(FaFacebookF)`
  color: ${(props) => props.theme.colors.primary};
  size: 2rem;
`;

export const TwitterIcon = styled(FaTwitter)`
  color: ${(props) => props.theme.colors.primary};
  size: 2rem;
`;

export const WhatsappIcon = styled(FaWhatsapp)`
  color: ${(props) => props.theme.colors.primary};
  size: 2rem;
`;

export const SecaoMenuItem = styled(CompSecaoMenuItem)`
  font-size: 1rem;
`;

export const SecaoBotaoVoltar = styled(CompSecaoBotaoVoltar)`
  font-size: 1rem;
`;

export const Noticia = styled.div`
  margin-bottom: 15px;
  display: inline-block;
`;

export const NoticiaImagemContainer = styled.div`
  height: 10rem;
  float: left;
  width: 35%;
  overflow: hidden;
  position: relative;
`;

export const NoticiaTitulo = styled.h1`
  font-size: 2rem;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const LegendaImagem = styled.p`
  text-align: center;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary};
`;

export const ImagemResponsiva = styled(BannerInternas)`
  display: block;
  width: 100%;
  margin-bottom: 30px;
`;

export const Conteudo = styled.div`
  margin-bottom: 3rem;
  font-size: 1rem;
`;

export const Tags = styled.div``;

export const TextoTags = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-style: italic;
`;

export const Tag = styled.span`
  padding: 5px;
  font-style: italic;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.primary};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.primary};
  border-radius: 7px;
  line-height: 40px;
`;
