import React from 'react';
import PropTypes from 'prop-types';
import Aplicativo from './Aplicativo';
import { TextoBaixeNossoApp } from './styles';
import { useTranslation } from 'react-i18next';

const Aplicativos = ({ itens = [] }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextoBaixeNossoApp>
        {t('componentes.rodape.baixeNossoApp')}
      </TextoBaixeNossoApp>

      {itens.map((item, idx) => (
        <Aplicativo key={`idx-${idx}`} {...item} />
      ))}
    </>
  );
};

Aplicativos.propTypes = {
  itens: PropTypes.array,
};

export default Aplicativos;
