import { Container as RsContainer } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

export const Container = styled(RsContainer)``;

export const FooterStyled = styled.footer`
  padding: 30px 0;
  background-color: ${(props) => props.theme.colors.secondary};

  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const LinkStyled = styled(Link)`
  line-height: 1.5em;
  margin-bottom: 5px;
  display: block;
  color: ${(props) => props.theme.colors.textInBg};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.textInBg};
  }
`;

export const LinkPrincipalStyled = styled(LinkStyled)`
  margin-bottom: 15px;
  font-weight: bold;
`;

export const ExternalLinkStyled = styled.a`
  line-height: 1.5em;
  margin-bottom: 5px;
  display: block;
  color: ${(props) => props.theme.colors.textInBg};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.textInBg};
  }
`;

export const ExternalLinkPrincipalStyled = styled(ExternalLinkStyled)`
  margin-bottom: 15px;
  font-weight: bold;
`;

export const Itens = styled.div`
  margin-bottom: 20px;
`;

export const TextoBaixeNossoApp = styled.p`
  margin-bottom: 15px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.textInBg};
`;

export const BotaoLojaAplicativo = styled.button`
  border: 0;
  border-radius: 0.75rem;
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.secondary};
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const IconeLoja = styled.span`
  font-size: 2.2rem;
  line-height: 1em;
  padding: 0.5rem;
`;

export const BotaoContainerTextos = styled.div`
  padding: 0.5rem;
`;

export const TextoDisponivelNa = styled.p`
  margin: 0;
  text-align: left;
  line-height: 1em;
  font-weight: bold;
  font-size: 0.75rem;
`;

export const TextoNomeLoja = styled(TextoDisponivelNa)`
  font-size: 1.3rem;
`;
