import { css } from 'styled-components';
import { darken } from 'polished';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const defaultStyles = css`
  html {
    scroll-behavior: smooth;
    font-size: 12px;

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      font-size: 14px;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      font-size: 16px;
    }
  }

  body {
    font-family: Arial, sans-serif;
    color: ${(props) => props.theme.colors.text};

    margin-top: 63px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: ${(props) => props.theme.colors.primary};
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-shadow: 0px 3px 6px #00000029;
  }

  a {
    color: ${(props) => props.theme.colors.primary};

    &:focus,
    &:hover {
      color: ${(props) => darken(0.1, props.theme.colors.primary)};
    }
  }

  .container-fluid {
    max-width: 1650px;
  }

  .btn {
    border-radius: 0;
  }

  .btn-primary {
    border-color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.textInBg};

    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle,
    &:focus,
    &:hover {
      border-color: ${(props) => darken(0.1, props.theme.colors.primary)};
      background-color: ${(props) => darken(0.1, props.theme.colors.primary)};
      box-shadow: none;
    }
  }

  .btn-secondary {
    border-color: ${(props) => props.theme.colors.secondary};
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.textInBg};

    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle,
    &:focus,
    &:hover {
      border-color: ${(props) => darken(0.1, props.theme.colors.secondary)};
      background-color: ${(props) => darken(0.1, props.theme.colors.secondary)};
      box-shadow: none;
    }
  }

  .text-justify {
    text-align: justify !important;
  }
  .modal-body {
    max-width: 100%;
    @media (max-width: 768px) {
      width: 300px;
      height: auto;
      display: flex;
    }
  }
  .modal-body img {
    max-width: 100%;
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      width: 300px;
      height: auto;
      display: flex;
    }
  }

  .modal-body a {
    width: 100%;
    height: auto;
    display: flex;
    @media (max-width: 768px) {
      max-width: 300px;
      height: 100%;
      display: flex;
    }
  }

  .modal-content {
    @media (max-width: 768px) {
      margin: 0 30px;
      max-width: 300px;
    }
  }

  .modal-header {
    border-bottom: 0px solid #dee2e6 !important;
  }

  .modal-footer {
    border-top: 0px solid #dee2e6 !important;
  }

  .mb-30 {
    margin-bottom: 30px !important;
  }

  .mb-50 {
    margin-bottom: 50px !important;
  }

  .mt-70 {
    margin-top: 70px;
  }

  .mt-30 {
    margin-top: 30px;
  }
  .margin-top-40graus {
    margin-top: 0px;
    @media (max-width: 768px) {
      margin-top: 80px;
    }
  }
  .mt-130 {
    margin-top: 0px;
    @media (max-width: 768px) {
      margin-top: 130px;
    }
  }

  .m-height-290 {
    height: 175px !important;
    width: auto;
  }

  .m-height-200 {
    height: 200px !important;
    width: auto;
  }

  .m-negative-30 {
    margin-top: -30px !important;
    width: auto;
    @media (max-width: 768px) {
      margin-top: 0px !important;
    }
  }

  .mb-100 {
    margin-bottom: 70px !important;
  }

  .justify-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .align-center {
    text-align: center;
  }

  .font-size14rem {
    font-size: 1.4rem;
  }

  .font-bold-blue {
    font-weight: bold;
    color: #0d2580 !important;
  }
`;

export default defaultStyles;
