import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

const goBackButton = ({ ...props }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <a onClick={goBack} {...props} href="javascript:;">
      {`<< ${t('componentes.secao.botaoVoltar')}`}
    </a>
  );
};

export default goBackButton;
