export default {
  title: 'default',

  colors: {
    silver: '#ddd',
    primary: '#D01B8B',
    secondary: '#29017C',
    tertiary: '#f297cf',
    text: '#A6A4A7',
    textInBg: '#fff',
    borders: '#707070',
    borderBotaoEMerkator: '#ffffff',
    boxShadow: '#000000',
    textBlue: '#67A9CE',
  },
  transparentColors: {
    primary: 'rgba(208,27,139,0.5)',
    secondary: 'rgba(41,1,124,0.7)',
    tertiary: 'rgba(165,0,31,0.8)',
  },
};
