import styled from 'styled-components';
import {
  Dropdown as RsDropdown,
  DropdownMenu as RsDropdownMenu,
  DropdownToggle as RsDropdownToggle,
} from 'reactstrap';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Dropdown = styled(RsDropdown)``;

export const DropdownToggle = styled(RsDropdownToggle)`
  font-size: 1rem;
  padding: 0.5rem 3rem;
  font-weight: bold;
`;

export const DropdownMenu = styled(RsDropdownMenu)`
  color: ${(props) => props.theme.colors.textInBg};
  background-color: ${(props) => props.theme.colors.primary};
  padding: 30px;
`;

export const Form = styled.form`
  color: ${(props) => props.theme.colors.textInBg};
`;

export const EsqueciMinhaSenha = styled.a`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.textInBg};
  cursor: pointer;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.textInBg};
    text-decoration: underline;
  }
`;

export const Title = styled.span`
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.textInBg};
  margin-bottom: 15px;
  display: block;
`;

export const InputSubmit = styled.input`
  margin-bottom: 15px;
`;

export const Input = styled.input`
  font-size: 1rem;
  box-sizing: border-border-box;
  color: ${(props) => props.theme.colors.primary};
  border-radius: 15px;
  border: 0;
  width: 300px;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 15px;

  &::placeholder {
    color: ${(props) => props.theme.colors.primary};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${(props) => props.theme.colors.primary};
  }

  &::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.primary};
  }
`;
