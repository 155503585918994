import styled, { createGlobalStyle } from 'styled-components';
export { Col, Row } from 'reactstrap';
export {
  Secao,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoMenuItem,
  SecaoBotaoVoltar,
} from '../../components/Secao';
export { Tradutor } from '../../components/I18n';

export const Styles = createGlobalStyle`
  .pagina-e-merkator-tv {
  }
`;
export const ReloadPosts = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TextReload = styled.p`
  display: flex;
  width: 20%;
  flex-direction: center;
  align-items: center;
  border-color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textInBg};
  flex-direction: column;
  padding: 5px;
`;

export const TitleVideo = styled.h3`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary};
`;
