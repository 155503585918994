import React from 'react';
import PropTypes from 'prop-types';
import {
  BotaoContainerTextos,
  BotaoLojaAplicativo,
  IconeLoja,
  TextoDisponivelNa,
  TextoNomeLoja,
} from './styles';
import { useTranslation } from 'react-i18next';

const Aplicativo = ({ Icone = null, textoNomeLoja = '', linkApp = '' }) => {
  const { t } = useTranslation();

  const goToApp = (linkApp) => {
    window.open(linkApp, '_blank');
  };

  return (
    <BotaoLojaAplicativo onClick={() => goToApp(linkApp)}>
      <IconeLoja>{Icone ? <Icone /> : ''}</IconeLoja>
      <BotaoContainerTextos>
        <TextoDisponivelNa>
          {t('componentes.rodape.disponivelNa')}
        </TextoDisponivelNa>
        <TextoNomeLoja>{textoNomeLoja}</TextoNomeLoja>
      </BotaoContainerTextos>
    </BotaoLojaAplicativo>
  );
};

Aplicativo.propTypes = {
  Icone: PropTypes.any,
  textoNomeLoja: PropTypes.string,
  linkApp: PropTypes.string,
};

export default Aplicativo;
