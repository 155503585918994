import styled from 'styled-components';
import Banner from '../../components/Banner';
import {
  SecaoMenuItem as CompSecaoMenuItem,
  SecaoBotaoVoltar as CompSecaoBotaoVoltar,
} from '../../components/Secao';

export { Link } from 'react-router-dom';
export { Col, Row } from 'reactstrap';
export { default as ImageCover } from '../../components/ImageCover';
export { Tradutor } from '../../components/I18n';

export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../components/Secao';

export const SecaoMenuItem = styled(CompSecaoMenuItem)`
  font-size: 1rem;
`;

export const SecaoBotaoVoltar = styled(CompSecaoBotaoVoltar)`
  font-size: 1rem;
`;

export const Noticia = styled.div`
  margin-bottom: 15px;
  display: inline-block;
  min-width: 100%;
  max-width: 100%;
`;

export const NoticiaImagemContainer = styled.div`
  height: 15rem;
  float: left;
  width: 40%;
  overflow: hidden;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const NoticiaTitulo = styled.p`
  float: left;
  width: 60%;
  padding: 0;
  margin: 0;
  padding-left: 15px;
  display: inline-block;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 0px;
  }
`;

export const ImagemResponsiva = styled(Banner)`
  display: block;
  margin-left: auto;
`;

export const ReloadPosts = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TextReload = styled.p`
  display: flex;
  width: 20%;
  flex-direction: center;
  align-items: center;
  border-color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textInBg};
  flex-direction: column;
  padding: 5px;
`;

export const SectionCenter = styled.div`
  width: 100%;
  min-width: 100%;
  min-height: 300px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
