import { Link } from 'react-router-dom';
import styled from 'styled-components';

export { Tradutor } from '../../../components/I18n';

export { Row, Col } from 'reactstrap';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoSubTitulo,
} from '../../../components/Secao';

export { default as Banner } from '../../../components/Banner';

export const Botao = styled(Link)`
  display: block;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 30px;
  height: 200px;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.boxShadow}29;
  border: 1px solid ${(props) => props.theme.colors.borderBotaoEMerkator};
`;

export const BotaoLinkExterno = styled.a`
  display: block;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 30px;
  height: 200px;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.boxShadow}29;
  border: 1px solid ${(props) => props.theme.colors.borderBotaoEMerkator};
`;

export const TextoBotao = styled.span`
  color: ${(props) => props.theme.colors.textInBg};
  font-size: 2rem;
  line-height: 2.5rem;
  display: block;
  width: 100%;
  padding: 30px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export const ImagemCover = styled(Botao)`
  background-color: transparent;
  height: 150px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
`;

export const ImagemCoverExterno = styled(BotaoLinkExterno)`
  background-color: transparent;
  height: 150px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
`;

export const BigImagemCover = styled(ImagemCover)`
  height: 200px;
`;

export const Conteudo = styled.div``;
