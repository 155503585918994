import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';

export {
  ImFacebook as FacebookIcon,
  ImInstagram as InstagramIcon,
  ImTwitter as TwitterIcon,
  ImLinkedin as LinkedinIcon,
} from 'react-icons/im';

export {
  FaApple as AppleIcon,
  FaGooglePlay as GooglePlayIcon,
} from 'react-icons/fa';

export { SiTiktok as TikTok } from 'react-icons/si';

export const Botao = styled(Link)`
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  color: ${(props) => props.theme.colors.primary};
  width: 2rem;
  height: 2rem;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;
