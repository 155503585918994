import styled from 'styled-components';
import { Container as RsContainer } from 'reactstrap';

export const Container = styled(RsContainer)`
  margin-bottom: 30px;
`;

export const Nav = styled.nav.attrs(({ className }) => ({
  className: `d-flex align-items-center justify-content-between position-relative ${className}`,
}))`
  padding-top: 5rem;
  padding-bottom: 2rem;
`;

export const Logo = styled.img`
  height: auto;
  width: 100%;
  max-width: 320px;
`;
