import React from 'react';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';

import {
  DropdownMenu as RsDropdownMenu,
  Navbar as RsNavbar,
  NavLink as RsNavLink,
  Dropdown as RsDropdown,
} from 'reactstrap';

export { Collapse, DropdownToggle, Nav, NavItem } from 'reactstrap';

export const Navbar = styled(RsNavbar)`
  border-width: 2px 0 2px 0;
  padding: 1.5rem 0 1.5rem 0;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.primary};

  .navbar-nav {
    width: 100%;

    .nav-item {
      width: 16.66%;
      text-align: center;
    }
  }

  .navbar-nav .nav-link {
    padding: 0;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
`;

export const MegaMenuColumn = styled.div`
  min-width: 25%;
  width: 25%;
  float: left;
`;

export const DropdownMegaMenu = styled(RsDropdown)`
  position: initial;

  & > .dropdown-menu {
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.93);
    padding: 39px 30px 15px 30px;
    box-shadow: 0 3px 6px ${(props) => props.theme.colors.primary}99;
    border: 0;
    border-radius: 0;
    border-left-width: 10px;
    border-left-style: solid;
    border-left-color: ${(props) => props.theme.colors.primary};
  }
`;

export const DropdownMenu = styled(RsDropdownMenu)`
  margin-left: 16.66%;
  margin-right: 16.66%;
`;

export const DropdownLink = styled(Link)`
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.1rem;
  display: block;
  padding-right: 10px;
`;

export const NavLink = ({ ...props }) => <RsNavLink tag={Link} {...props} />;

export const NavExternalLink = ({ ...props }) => <RsNavLink {...props} />;
