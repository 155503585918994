import React from 'react';
import PropTypes from 'prop-types';

import { Imagem } from './styles';

const Banner = ({ src, className = '', alt = '' }) => (
  <Imagem className={`${className}`} src={src} alt={alt}/>
);

Banner.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default Banner;
