const defaulScrollConfig = { behavior: 'smooth' };

const useScroll = (scrollConfig = defaulScrollConfig) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      ...defaulScrollConfig,
      ...scrollConfig,
    });
  };

  const scrollToRef = (ref = null) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView(scrollConfig);
    }
  };

  const scrollToHash = (hash = '') => {
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView(scrollConfig);
    }
  };

  const getCurrentHash = () => {
    const { hash } = window.location;
    let currentHash = '';

    if (hash && hash !== '') {
      const splitedHash = String(hash).split('#');
      if (splitedHash && splitedHash.length > 0) {
        currentHash = splitedHash[1];
      }
    }

    return currentHash;
  };

  return { scrollToTop, scrollToRef, scrollToHash, getCurrentHash };
};

export default useScroll;
