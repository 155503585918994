import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { NavHashLink } from 'react-router-hash-link';

import {
  Navbar as RsNavbar,
  NavbarBrand as RsNavbarBrand,
  NavLink as RsNavLink,
} from 'reactstrap';

export { NavItem, Collapse, Nav } from 'reactstrap';

export const Logo = styled.img`
  width: 100px;
`;

export const Navbar = styled(RsNavbar)`
  background-color: ${(props) => props.theme.colors.tertiary} !important;
`;

const NavLinkStyled = styled(RsNavLink)`
  color: ${(props) => props.theme.colors.primary};
`;

export const NavLink = ({ ...props }) => (
  <NavLinkStyled tag={NavHashLink} {...props} />
);

export const NavExternalLink = ({ ...props }) => <NavLinkStyled {...props} />;

export const NavbarBrand = ({ ...props }) => (
  <RsNavbarBrand tag={Link} {...props} />
);

export const NavbarToggler = styled.button`
  font-size: 20px;
  line-height: 1.5em;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
`;
