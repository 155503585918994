import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import api from '../../services/api';

import Loader from '../../components/Loader';
import Head from '../../components/Head';

import {
  Secao,
  SecaoCabecalho,
  SecaoMenu,
  SecaoMenuLista,
  SecaoMenuNav,
  SecaoTitulo,
  Tradutor,
  SecaoBotaoVoltar,
  SecaoMenuItem,
  SectionCenter,
  Box,
} from './styles';
import ImageGallery from './ImageGallery';
import { GoBackButton } from '../../components/Secao';

function Galeria() {
  const { categoriaSlug } = useParams();
  const [galleryView, setGalleryView] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGalleryView();
    return function cleanup() {};
  }, []);

  const handleGalleryView = async () => {
    await api.get(`blnews/${categoriaSlug}`).then((response) => {
      setTitle(response.data[0].title);
      setDescription(response.data[0].description);
      _handleCreateGallery(response.data[0].bl_gallery.data.id);
    });
  };
  const _handleCreateGallery = async (data) => {
    const arrImages = [];
    await api.get(`blimagegallery?gallery=${data}`).then((response) => {
      response.data._embedded.bl_image_gallery.forEach(function (e) {
        arrImages.push({
          original: e.file,
          thumbnail: e.file,
          description: e.alt_text_description,
        });
      });
      setGalleryView(arrImages);
      setIsLoading(true);
    });
  };

  const changeHead = useCallback(() => {
    return <Head {...{ title, description }} />;
  }, [title, description]);

  return (
    <Secao>
      {changeHead()}
      <SecaoCabecalho className="mb-5">
        <SecaoTitulo>
          <Tradutor path={title} />
        </SecaoTitulo>
        <SecaoMenu>
          <SecaoMenuNav>
            <SecaoMenuLista>
              <SecaoMenuItem>
                <GoBackButton />
              </SecaoMenuItem>
            </SecaoMenuLista>
          </SecaoMenuNav>
        </SecaoMenu>
      </SecaoCabecalho>
      <Box>
        {!isLoading && (
          <SectionCenter>
            <Loader />
          </SectionCenter>
        )}
        {isLoading && <ImageGallery items={galleryView} />}
      </Box>
    </Secao>
  );
}

export default Galeria;
