import styled from 'styled-components';

const Botao = styled.button`
  border-radius: 50%;
  background-color: transparent;
  border: 0;
  padding: 0;
`;

export default Botao;
