import React from 'react';
import { useTranslation } from 'react-i18next';

import Head from '../../components/Head';

const Pagina404 = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Head
        title={t('paginas.pagina404.titulo')}
        description={t('paginas.pagina404.titulo')}
      />
      <h1 style={{ margin: '30px 0', textAlign: 'center' }}>
        {t('paginas.pagina404.titulo')}
      </h1>
    </React.Fragment>
  );
};

export default Pagina404;
