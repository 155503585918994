import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Head from '../../components/Head';

import Video from '../../components/Video';
import RepositoryVideo from './RepositoryVideos';
import {
  Row,
  Styles,
  Secao,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoBotaoVoltar,
  ReloadPosts,
  TextReload,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoMenuItem,
  TitleVideo,
} from './styles';
import blNewsService from '../../services/blNewsService';

function EMerkatorTv() {
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const { t } = useTranslation();
  const { switchSizeImage } = blNewsService();

  const { posts, hasMore, loading, error } = RepositoryVideo(query, pageNumber);
  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <div className="pagina-e-merkator-tv">
      <Styles />
      <Head
        title={t('paginas.eMerkatorTv.titulo')}
        description={t('paginas.eMerkatorTv.titulo')}
      />
      <Secao className="mb-4">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <h1>{t('paginas.eMerkatorTv.titulo')}</h1>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>
        <Row>
          {posts.map((data, index) => {
            if (posts.length === index + 1) {
              return (
                <div
                  className="col-md-4 mb-50"
                  ref={lastBookElementRef}
                  key={data.id}
                >
                  <Video
                    imagemPlaceholder={switchSizeImage(data.image, 'md')}
                    imageDescription={
                      data.image_description
                        ? data.image_description
                        : data.description
                    }
                    videoEmbedded={data.video}
                    showIcon={true}
                  />
                  <img
                    src={switchSizeImage(data.image, 'md')}
                    alt={
                      data.image_description
                        ? data.image_description
                        : data.description
                    }
                  />
                  <TitleVideo>{data.title}</TitleVideo>
                </div>
              );
            } else {
              return (
                <div className="col-md-4 mb-50" key={index}>
                  <Video
                    imagemPlaceholder={switchSizeImage(data.image, 'lg')}
                    imageDescription={
                      data.image_description
                        ? data.image_description
                        : data.description
                    }
                    videoEmbedded={data.video}
                    showIcon={true}
                  />
                  <TitleVideo>{data.title}</TitleVideo>
                </div>
              );
            }
          })}
        </Row>
        {loading && (
          <ReloadPosts md="12">
            <TextReload>Carregando</TextReload>
          </ReloadPosts>
        )}
        <div>{error && 'Error'}</div>
      </Secao>
    </div>
  );
}

export default EMerkatorTv;
