import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle as RsDropdownToggle,
  UncontrolledDropdown as RsUncontrolledDropdown,
} from 'reactstrap';
import BrasilFlag from '../../assets/brasil-flag.svg';
import EspanhaFlag from '../../assets/espanha-flag.svg';
import ReinoUnidoFlag from '../../assets/reino-unido-flag.svg';

import Bandeira from './Bandeira';
import Botao from './Botao';
import useWindowSize from '../../hooks/useWindowSize';
import LANGUAGES from '../../i18n/languages';

const UncontrolledDropdown = styled(RsUncontrolledDropdown)`
  margin-right: 5px;
  color: ${(props) => props.theme.colors.primary};
`;

const BotaoStyled = styled(Botao)`
  margin-left: 5px;
  width: 2rem;
  height: 2rem;
`;

const BandeiraMobile = styled(Bandeira)`
  margin-left: 5px;
`;

const BandeiraDropdown = styled(Bandeira)`
  margin-right: 5px;
  width: 24px;
`;

const DropdownToggle = styled(RsDropdownToggle)`
  background-color: transparent;
  border: 0;

  &::after {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Mobile = ({ handleChangeLanguage, selectedLanguage }) => {
  const { t } = useTranslation();

  let BandeiraSelecionada = '';

  switch (selectedLanguage) {
    case LANGUAGES.pt.code: {
      BandeiraSelecionada = BrasilFlag;
      break;
    }
    case LANGUAGES.en.code: {
      BandeiraSelecionada = ReinoUnidoFlag;
      break;
    }
    case LANGUAGES.es.code: {
      BandeiraSelecionada = EspanhaFlag;
      break;
    }
    default:
      break;
  }

  return (
    <UncontrolledDropdown className="d-inline-block bg-transparent">
      <DropdownToggle className="bg-transparent" caret>
        <BandeiraDropdown image={BandeiraSelecionada} isSelected={true} />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem className="text-center" header>
          {t('componentes.cabecalho.linguagem')}
        </DropdownItem>
        <DropdownItem
          className="text-right"
          onClick={() => handleChangeLanguage(LANGUAGES.pt.urlLang)}
        >
          {t('componentes.cabecalho.portugues')}
          <BandeiraMobile
            image={BrasilFlag}
            isSelected={selectedLanguage === LANGUAGES.pt.code}
          />
        </DropdownItem>
        <DropdownItem
          className="text-right"
          onClick={() => handleChangeLanguage(LANGUAGES.en.code)}
        >
          {t('componentes.cabecalho.ingles')}
          <BandeiraMobile
            image={ReinoUnidoFlag}
            isSelected={selectedLanguage === LANGUAGES.en.urlLang}
          />
        </DropdownItem>
        <DropdownItem
          className="text-right"
          onClick={() => handleChangeLanguage(LANGUAGES.es.code)}
        >
          {t('componentes.cabecalho.espanhol')}
          <BandeiraMobile
            image={EspanhaFlag}
            isSelected={selectedLanguage === LANGUAGES.es.urlLang}
          />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

Mobile.propTypes = {
  handleChangeLanguage: PropTypes.func,
  selectedLanguage: PropTypes.string,
};

const Desktop = ({ handleChangeLanguage, selectedLanguage }) => (
  <div className="flags-container">
    <BotaoStyled
      className="ml-0"
      onClick={() => handleChangeLanguage(LANGUAGES.pt.urlLang)}
    >
      <Bandeira
        image={BrasilFlag}
        isSelected={selectedLanguage === LANGUAGES.pt.code}
      />
    </BotaoStyled>

    <BotaoStyled onClick={() => handleChangeLanguage(LANGUAGES.es.code)}>
      <Bandeira
        image={EspanhaFlag}
        isSelected={selectedLanguage === LANGUAGES.es.urlLang}
      />
    </BotaoStyled>

    <BotaoStyled onClick={() => handleChangeLanguage(LANGUAGES.en.code)}>
      <Bandeira
        image={ReinoUnidoFlag}
        isSelected={selectedLanguage === LANGUAGES.en.urlLang}
      />
    </BotaoStyled>
  </div>
);

Desktop.propTypes = {
  handleChangeLanguage: PropTypes.func,
  selectedLanguage: PropTypes.string,
};

const I18n = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const { width: widthPage } = useWindowSize();
  const selectedLanguage = i18n.language;
  const isMobile = widthPage < 768;

  function handleChangeLanguage(language) {
    // i18n.changeLanguage(language);
    history.push(`/${language}`);
  }

  if (isMobile) {
    return (
      <Mobile
        handleChangeLanguage={handleChangeLanguage}
        selectedLanguage={selectedLanguage}
      />
    );
  } else {
    return (
      <Desktop
        handleChangeLanguage={handleChangeLanguage}
        selectedLanguage={selectedLanguage}
      />
    );
  }
};

export default I18n;
