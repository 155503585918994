import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ThemeProvider as ScThemeProvider } from 'styled-components';

import defaultTheme from '../styles/themes/default';

const ThemeProvider = ({ children, currentTheme, ...props }) => {
  const theme = _.merge({}, defaultTheme, currentTheme);

  return (
    <ScThemeProvider theme={theme} {...props}>
      {children}
    </ScThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.any,
  currentTheme: PropTypes.object,
};

export default ThemeProvider;
