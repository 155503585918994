import styled from 'styled-components';
import {
  SecaoMenuItem as CompSecaoMenuItem,
  SecaoBotaoVoltar as CompSecaoBotaoVoltar,
} from '../../../components/Secao';

export { Col, Row } from 'reactstrap';
export { Tradutor } from '../../../components/I18n';

export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../../components/Secao';

export const SecaoMenuItem = styled(CompSecaoMenuItem)`
  font-size: 1rem;
  line-height: 2.5rem;
`;

export const SecaoBotaoVoltar = styled(CompSecaoBotaoVoltar)`
  font-size: 1rem;
  line-height: 2.5rem;
`;

export const SubTitulo = styled.h2`
  margin: 0;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textInBg};
  text-align: center;
  padding: 15px 0;
  font-weight: 700;
  margin-bottom: 30px;
`;

export const ImagemResponsiva = styled.div`
  display: block;
  background-color: #ccc;
  background-size: cover;
  background-position: center;
  height: 100px;

  @media screen and (min-width: 375px) {
    height: 150px;
  }

  @media screen and (min-width: 768px) {
    height: 150px;
  }
`;

export const Noticia = styled.div`
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
`;

export const NoticiaImagem = styled(ImagemResponsiva)`
  display: inline-block;
  width: 35%;
  float: left;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 160px;
    margin-bottom: 10px;
  }
`;

export const NoticiaTitulo = styled.p`
  width: 65%;
  padding: 0;
  margin: 0;
  padding-left: 15px;
  display: inline-block;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    font-size: 1.1rem;
    padding-left: 0;
  }
`;

export const Conteudo = styled.div``;

export const GridImagens = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;
`;

export const Box = styled.div`
  padding: 30px;
  border: 1px solid ${(props) => props.theme.colors.borders};
`;

export const TextoAgenciaTurismoOficial = styled.span`
  font-style: italic;
`;

export const QueroFalarComAcessoria = styled.a.attrs(({ className }) => ({
  className: `btn btn-primary d-block w-100 ${className}`,
}))`
  margin: 0;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 30px;
`;

export const Galeria = styled.div`
  .galery_container {
    margin-bottom: 15px;

    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }

  .galery_album_card {
    width: calc((100% - 15px * 2) / 3);
    height: 100px;
    float: left;
    margin-right: 15px;
    background-color: ${(props) => props.theme.colors.primary};
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .thumb_overlay {
    position: relative;
  }
  .galery_card {
    width: calc((100% - 15px * 2) / 2);
    white-space: normal;
    height: 100px;
    text-align: center;
    display: inline-block;
    margin-right: 15px;
    background-color: ${(props) => props.theme.colors.primary};
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 768px) {
    .galery_album_card {
      width: calc((100% - 15px * 4) / 5);
      height: 115px;
    }
    .galery_card {
      width: calc((100% - 15px * 4) / 4);
      height: 115px;
    }
  }

  @media screen and (min-width: 992px) {
    .galery_album_card {
      width: calc((100% - 15px * 4) / 5);
      height: 120px;
    }
    .galery_card {
      width: calc((100% - 15px * 4) / 4);
      height: 120px;
    }
  }

  @media screen and (min-width: 1200px) {
    .galery_album_card {
      width: calc((100% - 15px * 5) / 6);
      height: 130px;
    }
    .galery_card {
      width: calc((100% - 15px * 5) / 5);
      height: 130px;
    }
  }

  .cards {
    width: auto;
    white-space: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background: #c5bac1;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.primary};
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.primary};
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .galery_img {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .galery_overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.transparentColors.secondary};
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
  }

  .thumb_overlay:hover .galery_overlay {
    height: 100%;
  }

  .galery_text {
    color: ${(props) => props.theme.colors.textInBg};
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const SectionCenter = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
