/* eslint-disable react/prop-types */
import React from 'react';
import { withTheme } from 'styled-components';
import ReactLoaderSpinner from 'react-loader-spinner';

const Loader = (props) => (
  <ReactLoaderSpinner
    type="ThreeDots"
    color={`${props.theme.colors.primary}`}
    height={50}
    width={50}
    visible={true}
    {...props}
  />
);

export default withTheme(Loader);
