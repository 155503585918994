import React from 'react';
import PropTypes from 'prop-types';

const Bandeira = ({ image = '', isSelected = false, ...props }) => (
  <img
    alt="flag"
    src={image}
    className={`w-100 ${isSelected ? 'flag selected' : 'flag'}`}
    {...props}
  />
);

Bandeira.propTypes = {
  image: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default Bandeira;
