import React from 'react';
import { FaAngleLeft, FaAngleRight, FaFileDownload } from 'react-icons/fa';
import PropTypes from 'prop-types';
import blNewsService from '../../../../services/blNewsService';

import {
  Container,
  Image,
  NumberText,
  Slide,
  Text,
  ArrowLeft,
  ArrowRight,
  ButtonImageHigh,
} from './styles';

function Slider({ items, activeItem, changeActiveItem, ...props }) {
  const { switchSizeImage } = blNewsService();

  function getPrevItem() {
    let prevItem = activeItem - 1;

    if (prevItem < 0) {
      prevItem = items.length - 1;
    }

    return prevItem;
  }

  function getNextItem() {
    let nextItem = activeItem + 1;

    if (nextItem < 0 || nextItem >= items.length) {
      nextItem = 0;
    }

    return nextItem;
  }

  return (
    <Container {...props}>
      {items.map(({ original = '', description = '' }, idx) => {
        return (
          <Slide className={`${activeItem === idx ? 'active' : ''}`} key={idx}>
            <NumberText>
              {idx + 1} - {items.length}
            </NumberText>
            <Image alt={description} src={switchSizeImage(original, 'lg')} />
            <div>
              <Text>{description}</Text>
            </div>
            <ButtonImageHigh
              title={description}
              href={original}
              target="_blank"
              rel="noreferrer"
            >
              <FaFileDownload style={{ marginRight: 5 }} />
              Imagem em alta
            </ButtonImageHigh>
          </Slide>
        );
      })}
      <ArrowLeft onClick={() => changeActiveItem(getPrevItem())}>
        <FaAngleLeft />
      </ArrowLeft>

      <ArrowRight onClick={() => changeActiveItem(getNextItem())}>
        <FaAngleRight />
      </ArrowRight>
    </Container>
  );
}

Slider.propTypes = {
  items: PropTypes.array,
  activeItem: PropTypes.number,
  changeActiveItem: PropTypes.func,
};

export default Slider;
