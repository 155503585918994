import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LinkVoltar } from './styles';

const SecaoBotaoVoltar = ({ ...props }) => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <LinkVoltar to={`/${lang}/#cabecalho`} {...props}>
      {`<< ${t('componentes.secao.botaoVoltar')}`}
    </LinkVoltar>
  );
};

export default SecaoBotaoVoltar;
