import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { Form, Title, Input, InputSubmit } from './styles';

function FormForgotPassword({ toggleForgotPassword }) {
  const [email, setEmail] = useState('');

  const { t } = useTranslation();

  const submitForm = (event) => {
    event.preventDefault();
    if (IsValidEmail(email)) {
      alert('Digite um e-mail válido');
      return false;
    }
    document.getElementById('forgotPassword').submit();
  };
  function IsValidEmail(val) {
    const regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return 'Invalid Email';
    }
  }

  return (
    <Form
      method="POST"
      action="https://paineldoexpositor.com.br/usuario/forgot"
      id="forgotPassword"
      target="_blank"
    >
      <Title>{t('componentes.painelExpositor.esqueciMinhaSenha')}</Title>
      <Input
        name="email"
        type="email"
        value={email}
        placeholder={t('componentes.painelExpositor.email')}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputSubmit
        type="submit"
        className="btn btn-secondary btn-block"
        value={t('componentes.painelExpositor.entrar')}
        onClick={submitForm}
      />
      <InputSubmit
        type="button"
        className="btn btn-info btn-block"
        value={t('componentes.painelExpositor.cancelar')}
        onClick={toggleForgotPassword}
      />
    </Form>
  );
}

FormForgotPassword.propTypes = {
  toggleForgotPassword: PropTypes.func,
};

export default FormForgotPassword;
