import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownLink,
  DropdownMegaMenu,
  DropdownMenu,
  MegaMenuColumn,
  NavExternalLink,
  NavLink,
  Navbar,
  Collapse,
  DropdownToggle,
  Nav,
  NavItem,
} from './styles';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

const MenuDesktop = ({ menu, mostrarSubMenu }) => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function renderNavItem({ link, text, externalLink }, idx) {
    return (
      <NavItem key={`cabecalho-desktop-${idx}`}>
        {externalLink ? (
          <NavExternalLink href={link} target="_blank">
            {text}
          </NavExternalLink>
        ) : (
          <NavLink to={link}>{text}</NavLink>
        )}
      </NavItem>
    );
  }

  function renderMegaMenuLink({ link, text }, idx) {
    return (
      <DropdownLink key={`cabecalho-mega-menu~link-${idx}`} to={link}>
        {text}
      </DropdownLink>
    );
  }

  function renderMegaMenu(item, idx) {
    return (
      <MegaMenuColumn key={`cabecalho-mega-menu-link-${idx}`}>
        {item.subMenus.map(renderMegaMenuLink)}
      </MegaMenuColumn>
    );
  }

  return (
    <Navbar className="menu-desktop" expand="md">
      <Collapse isOpen={false} navbar>
        <Nav navbar>
          {mostrarSubMenu ? (
            <DropdownMegaMenu
              isOpen={dropdownOpen}
              toggle={toggle}
              nav
              inNavbar
            >
              <DropdownToggle nav>{t('cabecalho.menu')}</DropdownToggle>
              <DropdownMenu onClick={toggle}>
                {menu.map((item, itemIdx) =>
                  item.subMenus ? renderMegaMenu(item, itemIdx) : null
                )}
              </DropdownMenu>
            </DropdownMegaMenu>
          ) : (
            renderNavItem(
              {
                link: `/${lang}`,
                text: 'Home',
                externalLink: false,
              },
              'home'
            )
          )}
          {menu.map(renderNavItem)}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

MenuDesktop.propTypes = {
  menu: PropTypes.array,
  mostrarSubMenu: PropTypes.any,
};

export default MenuDesktop;
