import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';

const RoutesProvider = ({ children }) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};

RoutesProvider.propTypes = {
  children: PropTypes.any,
};

export default RoutesProvider;
