import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import GlobalStyles from './styles/GlobalStyles';
import Cabecalho from './components/Cabecalho';
import Rodape from './components/Rodape';

// eslint-disable-next-line react/prop-types
const Template = ({ children }) => (
  <Fragment>
    <GlobalStyles />
    <Cabecalho />
    <main>
      <Container fluid>{children}</Container>
    </main>
    <Rodape />
  </Fragment>
);

export default Template;
