import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormForgotPassword from './FormForgotPassword';
import FormLogin from './FormLogin';

import { DropdownToggle, Dropdown, DropdownMenu, Container } from './styles';

const PainelExpositor = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const { t } = useTranslation();

  const toggle = () => {
    const newIsOpen = !isOpen;

    if (!newIsOpen) {
      setIsForgotPassword(false);
    }

    setIsOpen(newIsOpen);
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword((prevState) => !prevState);
  };

  return (
    <Container>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle color="primary">
          {t('cabecalho.painelExpositor')}
        </DropdownToggle>
        <DropdownMenu right>
          {isForgotPassword ? (
            <FormForgotPassword toggleForgotPassword={toggleForgotPassword} />
          ) : (
            <FormLogin toggleForgotPassword={toggleForgotPassword} />
          )}
        </DropdownMenu>
      </Dropdown>
    </Container>
  );
};

export default PainelExpositor;
