import styled from 'styled-components';

export const Picture = styled.div`
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.colors.primary};
`;

export const Imagem = styled.img`
  opacity: 0;
  width: 100%;
  height: 100%;
`;
