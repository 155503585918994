import styled from 'styled-components';
import { SecaoMenuLista as CompSecaoMenuLista } from '../../components/Secao';
import ImageCover from '../../components/ImageCover';

export { Tradutor } from '../../components/I18n';

export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenuItem,
  SecaoBotaoVoltar,
} from '../../components/Secao';

export const SecaoMenuLista = styled(CompSecaoMenuLista)`
  justify-content: flex-end;
`;

export const Container = styled.div``;

export const SectionCenter = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div.attrs(({ className }) => ({
  className: `${className}`,
}))`
  min-height: 60vh;
`;

export const ImagemPrincipal = styled(ImageCover)``;

export const Slide = styled.div.attrs(({ className }) => ({
  className: `col-lg-2 col-md-2 ${className}`,
}))`
  overflow: hidden;
  height: 60vh;
`;

export const ImagemPequena = styled(ImageCover)`
  height: 100px;
`;
