import styled from 'styled-components';
import IgSlider from './Slider';
import IgThumbnails from './Thumbnails';

export const Container = styled.div`
  display: inline-block;
  height: 80vh;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: 40vh;
  }

  &:after {
    content: '';
    clear: both;
  }
`;

export const Slider = styled(IgSlider)`
  float: left;
  width: calc(100% - 250px);

  @media (max-width: 768px) {
    width: 100%;
    background-color: #ffffff;
  }
`;

export const Thumbnails = styled(IgThumbnails)`
  float: right;
  width: 250px;
`;

export const BotaoDownload = styled.button.attrs(({ className, ...props }) => ({
  className: 'btn btn-primary ' + className,
  ...props,
}))``;
