import React from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '@alweb-merkator/shared/hooks/useWindowSize';

import CabecalhoDesktop from './CabecalhoDesktop';
import CabecalhoMobile from './CabecalhoMobile';

const CabecalhoFeiras = ({
  medias,
  menu,
  logo,
  mostrarSubMenu = true,
  evento,
  ...props
}) => {
  const { width: widthPage } = useWindowSize();
  return (
    <header
      id="cabecalho"
      {...props}
      className={evento === '40-graus' && 'mt-130'}
    >
      {widthPage >= 768 ? (
        <CabecalhoDesktop
          medias={medias}
          menu={menu}
          logo={logo}
          mostrarSubMenu={mostrarSubMenu}
        />
      ) : (
        <CabecalhoMobile
          medias={medias}
          menu={menu}
          logo={logo}
          mostrarSubMenu={mostrarSubMenu}
        />
      )}
    </header>
  );
};

CabecalhoFeiras.propTypes = {
  medias: PropTypes.array,
  menu: PropTypes.array,
  mostrarSubMenu: PropTypes.any,
  logo: PropTypes.any,
  evento: PropTypes.any,
};

export default CabecalhoFeiras;
