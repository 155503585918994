export const postDefaultState = { post: null };

export default function PostReducer(state = postDefaultState, action = {}) {
  switch (action.type) {
    case 'POST_ADD':
      return { ...state, post: action.payload };
    case 'POST_REMOVE':
      return { ...state, post: null };
    default:
      return state;
  }
}
