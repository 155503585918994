import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import api from '@alweb-merkator/shared/services/api';
import { SectionCenter } from '@alweb-merkator/shared/pages/PaginaInicial/SecaoImprensa/styles.js';
import Loader from '@alweb-merkator/shared/components/Loader';
import { Imagem } from './styles';

// eslint-disable-next-line react/prop-types
const Banner = ({ className = '', idEvent }) => {
  const [banner, setBanner] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { lang } = useParams();

  const getDataConfig = async () => {
    await api.get(`/blconfiguration/${idEvent}`).then((response) => {
      let dataBanner = '';
      switch (lang) {
        case 'es':
          dataBanner = response.data.big_banner_es;
          break;
        case 'en':
          dataBanner = response.data.big_banner_en;
          break;
        default:
          dataBanner = response.data.big_banner_pt;
      }
      setBanner(
        `https://paineldoexpositor.com.br/uploads/configuration/${dataBanner}`
      );
      setIsLoading(true);
    });
  };
  useEffect(() => {
    getDataConfig();
  }, []);
  return (
    <>
      {isLoading ? (
        <Imagem className={`${className}`} src={banner} />
      ) : (
        <SectionCenter>
          <Loader />
        </SectionCenter>
      )}
    </>
  );
};

Banner.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
};

export default Banner;
