import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { Form, Title, Input, InputSubmit, EsqueciMinhaSenha } from './styles';
function FormLogin({ toggleForgotPassword }) {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const { t } = useTranslation();

  const submitForm = (event) => {
    event.preventDefault();
    if (IsValidEmail(email) && senha.length >= 8) {
      alert('Digite um e-mail válido');
      return false;
    }
    document.getElementById('forgotPassword').submit();
  };
  function IsValidEmail(val) {
    const regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return 'Invalid Email';
    }
  }

  return (
    <Form
      method="POST"
      action="https://paineldoexpositor.com.br/auth/login"
      id="forgotPassword"
      target="_blank"
    >
      <Title>{t('cabecalho.painelExpositor')}</Title>
      <Input
        name="email"
        type="email"
        value={email}
        placeholder={t('componentes.painelExpositor.email')}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        name="senha"
        type="password"
        value={senha}
        placeholder={t('componentes.painelExpositor.senha')}
        onChange={(e) => setSenha(e.target.value)}
      />
      <InputSubmit
        type="submit"
        className="btn btn-block btn-secondary"
        onClick={submitForm}
        value={t('componentes.painelExpositor.entrar')}
      />
      <EsqueciMinhaSenha onClick={toggleForgotPassword}>
        {t('componentes.painelExpositor.esqueciMinhaSenha')}
      </EsqueciMinhaSenha>
    </Form>
  );
}

FormLogin.propTypes = {
  toggleForgotPassword: PropTypes.func,
};

export default FormLogin;
