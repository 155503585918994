import React from 'react';
import PropTypes from 'prop-types';
import blNewsService from '../../../../services/blNewsService';

import { Container, ListThumbnails, Thumbnail } from './styles';

function Thumbnails({ items, activeItem, changeActiveItem }) {
  const { switchSizeImage } = blNewsService();

  return (
    <Container>
      <ListThumbnails>
        {items.map(
          ({ original = '', thumbnail = '', description = '' }, idx) => {
            return (
              <Thumbnail
                key={idx}
                className={activeItem === idx ? 'active' : ''}
                onClick={() => changeActiveItem(idx)}
                title={description}
              >
                <img src={switchSizeImage(thumbnail, 'md')} alt={description} />
              </Thumbnail>
            );
          }
        )}
      </ListThumbnails>
    </Container>
  );
}

Thumbnails.propTypes = {
  items: PropTypes.array,
  activeItem: PropTypes.number,
  changeActiveItem: PropTypes.func,
};

export default Thumbnails;
